<template>
  <div class="library-section-wrapper">
    <LibraryHeader :mentor="mentor" />
    <LibraryFilters :is-mentor="false" @onFilter="filterRecords" />
    <LibraryContents :is-mentor="false" />
  </div>
</template>

<script>
import Vue from "vue";
import LibraryHeader from "@/components/Library/LibraryHeader";
import LibraryFilters from "@/components/Library/LibraryFilters";
import LibraryContents from "@/components/Library/LibraryContents";

export default Vue.extend({
  name: "MentoringPlanPage",
  components: {
    LibraryHeader,
    LibraryFilters,
    LibraryContents
  },
  data() {
    return {
      mentor: {}
    };
  },
  async mounted() {
    const { mentor } = await this.$store.dispatch(
      "library_content/GET_ALL_CONTENT_BY_MENTOR",
      this.$route.params.id
    );
    this.mentor = mentor;
  },
  methods: {
    async filterRecords() {
      await this.$store.dispatch(
        "library_content/GET_ALL_CONTENT_BY_MENTOR",
        this.$route.params.id
      );
    }
  }
});
</script>
